import PageFullWidth from "components/Layout/PageFullWidth";
import { useState } from "react";

import { Carousel, FormSwap, Note } from "./components";
import { CsContainer, CsFormSwap } from "./styles";

export const HomePage = () => {
    const [feePercent, setFeePercent] = useState("")
    return (
        <PageFullWidth>
            <CsContainer>
                <Carousel />
                <CsFormSwap>
                     <FormSwap onHandleChangeFee={(newFee) => setFeePercent(newFee)} />
                </CsFormSwap>
            </CsContainer>
        </PageFullWidth>
    )
};