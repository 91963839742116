import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 22 22" {...props}>
            <path d="M12.0253 15.6833C11.8669 15.6833 11.7086 15.625 11.5836 15.5C11.3419 15.2583 11.3419 14.8583 11.5836 14.6167L16.2003 9.99999L11.5836 5.38333C11.3419 5.14166 11.3419 4.74166 11.5836 4.49999C11.8253 4.25833 12.2253 4.25833 12.4669 4.49999L17.5253 9.55833C17.7669 9.79999 17.7669 10.2 17.5253 10.4417L12.4669 15.5C12.3419 15.625 12.1836 15.6833 12.0253 15.6833Z" fill="#292D32" />
            <path d="M16.942 10.625H2.91699C2.57533 10.625 2.29199 10.3417 2.29199 10C2.29199 9.65833 2.57533 9.375 2.91699 9.375H16.942C17.2837 9.375 17.567 9.65833 17.567 10C17.567 10.3417 17.2837 10.625 16.942 10.625Z" fill="#292D32" />
        </Svg>
    );
};

export default Icon;
