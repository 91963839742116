


import { useTranslation } from '@pancakeswap/localization';
import "swiper/css";
import "swiper/css/pagination";

import { CsCarousel, CsItemCarousel, CsLeftItemCarousel, CsRightItemCarousel } from "../styles";

export const Carousel = () => {
    return (
        <CsCarousel>
            {/* <Swiper pagination modules={[Pagination]}>
                <SwiperSlide>
                    <ItemCarousel
                        month="June 2023"
                        title="Hot Hot!!!"
                        description="When switching from another mainnet to Onus mainnet will be supported gas fee"
                        titleLink="Find out more"
                        href="/cross-chains"
                    />
                </SwiperSlide>
            </Swiper> */}
            <ItemCarousel
                month="June 2023"
                title="Hot Hot!!!"
                description="When switching from another mainnet to Onus mainnet will be supported gas fee"
                titleLink="Find out more"
                href="/cross-chains"
            />
        </CsCarousel>
    )
};


interface ItemCarouselProps {
    month: string,
    title: string,
    description: string,
    titleLink: string,
    href: string,
}

const ItemCarousel = (props: ItemCarouselProps) => {
    const { t } = useTranslation()
    return (
        <CsItemCarousel>
            <CsLeftItemCarousel>
                <p>{t(props.month)}</p>
                <h3>{t(props.title)}</h3>
                <span>{t(props.description)}</span>
            </CsLeftItemCarousel>
            <CsRightItemCarousel>
                <img className="desktop" src="/images/goldbridge/carousel.png?version=1.0" alt="Hot Hot!!!" />
                <img className="mobile" src="/images/goldbridge/carousel-mobile.png?version=1.0" alt="Hot Hot!!!" />
            </CsRightItemCarousel>
        </CsItemCarousel>
    )
};
