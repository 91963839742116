import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import { ArrowRight, AutoRenewIcon } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { listTokens } from 'config/tokenSupport'
import { configToken } from 'config/tokenSupport/type'
import { useCrossChains } from 'hooks'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useSwitchNetworkLocal } from 'hooks/useSwitchNetwork'
import * as React from 'react'
import { useSwitchNetwork, useBalance } from 'wagmi'
import {
    CsArrowRight,
    CsButtonSwap,
    CsInputSwap,
    CsItemField,
    CsWapButtonSwap,
    CsWrapButton,
    CsWrapItemField,
    CsWrapSelect,
} from '../styles'
import { BalanceForm } from './BalanceForm'
import { InputLeft } from './InputLeft'
import { InputRight } from './InputRight'
import { Note } from './Note'
import { PercentPrice } from './PercentPrice'
import { SelectAssetField } from './SelectAssetField'
import { SelectFromField } from './SelectFromField'
import { SelectToField } from './SelectToField'
import { NoteSupport } from './NoteSupport'

interface Props {
    onHandleChangeFee: (newFee) => void
}
export const FormSwap: React.FC<Props> = ({ onHandleChangeFee }) => {
    const { chainId, account } = useActiveWeb3React()
    const [selectToken, setSelectToken] = React.useState<configToken>()
    const [selectFromChain, setSelectFromChain] = React.useState<number>()
    const [toChain, setToChain] = React.useState<number>(null)
    const { switchNetworkAsync } = useSwitchNetwork()
    const switchNetworkLocal = useSwitchNetworkLocal()
    const [fillAmount, setFillAmount] = React.useState('')

    const findTokenFrom = React.useMemo(() => {
        return selectToken?.fromChains.find((dt) => dt?.chainId === chainId)?.token
    }, [chainId, selectToken?.fromChains])

    const findTokenTo: any = React.useMemo(() => {
        return selectToken?.fromChains.find((dt) => dt?.chainId === toChain)?.token
    }, [selectToken?.fromChains, toChain])
    const { t } = useTranslation()
    const { handleApproveToken, handleSwap, resultStoreCrossChains, loadingTx, dataFromToken } = useCrossChains(
        fillAmount,
        toChain,
        findTokenFrom,
        findTokenTo,
    )
    const { data } = resultStoreCrossChains

    const checkIsApproved = React.useMemo(() => {
        if (
            new BigNumber(dataFromToken?.allowance).isGreaterThanOrEqualTo(fillAmount) ||
            (new BigNumber(dataFromToken?.allowance).isGreaterThan(0) && new BigNumber(fillAmount).isNaN())
        )
            return true
        return false
    }, [fillAmount, dataFromToken?.allowance])

    const handleAction = async () => {
        if (!checkIsApproved) {
            handleApproveToken()
        } else {
            await handleSwap()
        }
    }
    // initial data
    React.useLayoutEffect(() => {
        const initial = listTokens.find((dt) => dt?.fromChains?.find((it) => it?.chainId === chainId))
        setSelectToken(initial)
        setSelectFromChain(initial?.fromChains?.find((dt) => dt?.chainId === chainId)?.chainId)
        const fistFromChain = initial?.fromChains?.find((dt) => dt?.chainId !== chainId)
        setToChain(fistFromChain?.chainId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const dataTokenTransfer = React.useMemo(() => {
        try {
            const tokenTransferInfo = data?.dataCrossChain[dataFromToken?.symbol]
            const sourceChain = tokenTransferInfo?.find((dt) => Number(dt?.chainid_source) === chainId && Number(dt?.chainid_destination) === toChain)
            const destinationChain = tokenTransferInfo?.find((dt) => Number(dt?.chainid_destination) === toChain)
            const isMintAmountTransfer = new BigNumber(fillAmount).isLessThan(sourceChain?.min_amount)
            const isMaxAmountTransfer = new BigNumber(fillAmount).isGreaterThan(sourceChain?.max_amount)
            const minAmount = sourceChain?.min_amount
            const maxAmount = sourceChain?.max_amount
            const rate = destinationChain?.rate_swap
            const isActive = sourceChain?.status === 'enable' ?? false
            const feePercent = sourceChain?.fee?.fee_percent
            const feeMin = sourceChain?.fee?.fee_min
            onHandleChangeFee(sourceChain?.fee?.fee_percent)
            return {
                isMintAmountTransfer,
                isMaxAmountTransfer,
                rate,
                minAmount,
                maxAmount,
                feePercent,
                feeMin,
                bonus: destinationChain?.amount_bonus,
                amountMinBonus: destinationChain?.amount_min_bonus,
                isActive,
            }
        } catch (error) {
            return {
                isMintAmountTransfer: false,
                isMaxAmountTransfer: false,
                minAmount: '0',
                maxAmount: '0',
                feePercent: '0',
                feeMin: '0',
                amountMinBonus: null,
                isActive: false,
            }
        }
    }, [chainId, data?.dataCrossChain, fillAmount, dataFromToken?.symbol, onHandleChangeFee, toChain])
    const { isMintAmountTransfer, isMaxAmountTransfer, rate, minAmount, maxAmount, feePercent, bonus, feeMin, amountMinBonus } =
        dataTokenTransfer
    const actuallyReceived = React.useMemo(() => {
        if (new BigNumber(fillAmount).isNaN() || new BigNumber(rate).isNaN() || new BigNumber(feePercent).isNaN())
            return '0.0'
        const inflation = new BigNumber(fillAmount).multipliedBy(0.01).toString()
        const priceInflation = new BigNumber(fillAmount).minus(inflation).toString()
        const priceRate = new BigNumber(priceInflation).multipliedBy(rate).toString()
        const pricePercent = new BigNumber(priceRate).multipliedBy(new BigNumber(feePercent).dividedBy(100)).toString()
        const fee = new BigNumber(pricePercent).isLessThan(feeMin) ? feeMin : pricePercent
        const resultPrice = new BigNumber(priceRate).minus(fee).decimalPlaces(4, 1).toString()
        return resultPrice
    }, [feeMin, feePercent, fillAmount, rate])

    const balanceFrom = account ? new BigNumber(dataFromToken?.balanceOf) : new BigNumber(0)
    const renderBalanceFrom =
        balanceFrom?.isEqualTo(0) || balanceFrom?.isNaN() || !account
            ? '0.0'
            : balanceFrom?.decimalPlaces(4, 1).toString()

    const handleSwapChain = async () => {
        try {
            if (account) {
                await switchNetworkAsync(toChain)
            } else {
                await switchNetworkLocal(toChain)
            }

            setSelectFromChain(toChain)
            setToChain(selectFromChain)
        } catch (error) {
            console.log(error)
        }
    }

    const balanceToNative = useBalance({ address: account, chainId: findTokenTo?.chainId, staleTime: 60000 })
    const balanceTo = useBalance({
        address: account,
        chainId: findTokenTo?.chainId,
        token: findTokenTo?.address,
        staleTime: 60000,
    })
    const nativeBalanceFrom = useBalance({ address: account, chainId: findTokenFrom?.chainId, staleTime: 60000 })

    const checkBalanceTo = findTokenTo?.isNative
        ? new BigNumber(balanceToNative?.data?.formatted || 0)?.decimalPlaces(4, 1).toString()
        : new BigNumber(balanceTo?.data?.formatted || 0)?.decimalPlaces(4, 1).toString()
    const checkBalanceFrom = findTokenFrom?.isNative
        ? new BigNumber(nativeBalanceFrom?.data?.formatted)?.decimalPlaces(4, 1).toString()
        : renderBalanceFrom

    const isNotBalanceToTransfer = React.useMemo(() => {
        return new BigNumber(fillAmount).isGreaterThan(checkBalanceFrom)
    }, [fillAmount, checkBalanceFrom])

    React.useEffect(() => {
        setFillAmount('')
    }, [checkBalanceFrom])

    const handleSelectToken = async (newToken: configToken) => {
        const initial = listTokens.find((dt) => dt?.name === newToken?.name)
        try {
            const findItem = initial?.fromChains?.find((dt) => dt?.chainId === chainId)
            if (!findItem) {
                if (account) {
                    await switchNetworkAsync(initial?.fromChains[0]?.chainId)
                } else {
                    switchNetworkLocal(initial?.fromChains[0]?.chainId)
                }
            }
            setSelectToken(newToken)
            if (findItem) {
                setToChain(initial?.toChains.find((dt) => dt?.chainId !== chainId)?.chainId)
                setSelectFromChain(chainId)
            } else {
                setToChain(initial?.toChains.find((dt) => dt?.chainId !== initial?.fromChains[0]?.chainId)?.chainId)
                setSelectFromChain(initial?.fromChains[0]?.chainId)
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <CsWrapSelect>
                {/* select left  */}
                <CsWrapItemField>
                    <BalanceForm title="From" value={checkBalanceFrom} />
                    <CsItemField>
                        <SelectAssetField
                            onChangeToken={(newToken: configToken) => handleSelectToken(newToken)}
                            name="from_token"
                            lineBottom
                            label={t('Asset')}
                            options={listTokens}
                            noBorder
                            selectedToken={selectToken?.symbol}
                            isDisabled={false}
                        />
                        <SelectFromField
                            chainId={chainId}
                            label={t('From')}
                            name="from_chains_networks"
                            options={selectToken?.fromChains}
                            selectFromChain={chainId}
                            noBorder
                            onChange={async (e: number) => {
                                try {
                                    if (account) {
                                        await switchNetworkAsync(e)
                                    } else {
                                        switchNetworkLocal(e)
                                    }
                                    setSelectFromChain(e)
                                    setToChain(selectToken?.toChains.find((dt) => dt?.chainId !== e)?.chainId)
                                } catch (error) {
                                    console.log(error)
                                }
                            }}
                        />
                    </CsItemField>
                    <InputLeft
                        balanceForFrom={checkBalanceFrom}
                        fillAmount={fillAmount}
                        onChangeAmount={(value) => setFillAmount(value)}
                        symbol={findTokenFrom?.symbol}
                        isMinAmount={isMintAmountTransfer}
                        isMaxAmount={isMaxAmountTransfer}
                        minAmount={minAmount}
                        maxAmount={maxAmount}
                        isNotBalanceToTransfer={isNotBalanceToTransfer}
                        childrenPercent={
                            <PercentPrice setFillAmount={setFillAmount} balanceFrom={account ? balanceFrom : null} />
                        }
                    />
                </CsWrapItemField>
                <CsArrowRight>
                    <CsWrapButton onClick={handleSwapChain}>
                        <ArrowRight />
                    </CsWrapButton>
                </CsArrowRight>
                {/* select right  */}
                <CsWrapItemField>
                    <BalanceForm title="To" value={checkBalanceTo} />
                    <CsItemField>
                        <SelectAssetField
                            onChangeToken={() => null}
                            name="to_token"
                            lineBottom
                            label={t('Asset')}
                            options={listTokens}
                            noBorder
                            selectedToken={selectToken?.symbol}
                            isDisabled={!false}
                        />
                        <SelectToField
                            name="to_chains_networks"
                            label={t('To')}
                            options={selectToken?.toChains?.filter(
                                (dt) => dt?.chainId !== chainId && dt?.fromChain?.includes(chainId),
                            )}
                            noBorder
                            onChange={(newChainId) => setToChain(newChainId)}
                            toChainId={toChain}
                        />
                    </CsItemField>
                    <InputRight fillAmount={actuallyReceived} symbol={dataFromToken?.symbol} balance={checkBalanceTo} />
                </CsWrapItemField>
            </CsWrapSelect>
            <NoteSupport toChain={toChain} findTokenTo={findTokenTo} bonus={bonus} amountMinBonus={amountMinBonus} />
            <CsInputSwap>
                <InputLeft
                    balanceForFrom={checkBalanceFrom}
                    fillAmount={fillAmount}
                    onChangeAmount={(value) => setFillAmount(value)}
                    symbol={findTokenFrom?.symbol}
                    isMinAmount={isMintAmountTransfer}
                    isMaxAmount={isMaxAmountTransfer}
                    minAmount={minAmount}
                    maxAmount={maxAmount}
                    isNotBalanceToTransfer={isNotBalanceToTransfer}
                    childrenPercent={
                        <PercentPrice setFillAmount={setFillAmount} balanceFrom={account ? balanceFrom : null} />
                    }
                />
                <InputRight fillAmount={actuallyReceived} symbol={findTokenTo?.symbol} balance={checkBalanceTo} />
            </CsInputSwap>
            <CsWapButtonSwap>
                {account && (
                    <CsButtonSwap
                        disabled={
                            loadingTx ||
                            isMintAmountTransfer ||
                            isMaxAmountTransfer ||
                            isNotBalanceToTransfer ||
                            dataTokenTransfer?.isActive === false
                        }
                        endIcon={loadingTx ? <AutoRenewIcon spin color="textDisabled" /> : null}
                        onClick={handleAction}
                    >
                        {/* {checkIsApproved || (new BigNumber(findTokenFromSwap?.allowance).isGreaterThan(0) && new BigNumber(findTokenFromSwap?.allowance).isEqualTo(new BigNumber(findTokenFromSwap?.balanceOf))) ? t("Swap"):t("Approve")} */}
                        {!checkIsApproved ? t('Approve') : t('Swap')}
                    </CsButtonSwap>
                )}
                {!account && (
                    <ConnectWalletButton>
                        <CsButtonSwap>{t('Connect Wallet')}</CsButtonSwap>
                    </ConnectWalletButton>
                )}
            </CsWapButtonSwap>
            <Note feePercent={feePercent} feeMin={feeMin} addressToSymbol={findTokenFrom?.symbol} />
        </>
    )
}
