import { useTranslation } from "@pancakeswap/localization";
import React, { useMemo } from "react";
import { CsInput, CsInputField, CsInputSwapComponent, CsNumericFormat, CsSuffix } from "../styles";

const imgUSDT = "/images/coins/usdt.png";

interface Props {
    fillAmount: string
    symbol: string,
    balance: string | number,
}
export const InputRight: React.FC<Props> = ({
    fillAmount,
    symbol,
    balance,
}) => {
    const { t } = useTranslation()
    const renderImg = useMemo(() => {
        return `/images/coins/${symbol?.toLowerCase()}.png`
    }, [symbol])
    return (
        <CsInputSwapComponent>
            <CsInputField>
                <div className="flex justify-between">
                    <p className="text-[#131316] font-bold text-[14px]">{t("Amount")}</p>
                    <p className="text-[#8F8F9B] font-bold text-[14px]">{t("Balance")}: {balance}</p>
                </div>
                <CsInput>
                    <CsNumericFormat
                        value={fillAmount}
                        decimalScale={6}
                        thousandSeparator={!false}
                        maxLength={35}
                        allowNegative={false}
                        readOnly
                    />
                    <CsSuffix>
                        <img className="sm:w-[32px] sm:h-[32px] w-6 h-6" src={symbol ? renderImg : imgUSDT} alt="" />
                        <p className="sm:text-sm text-xs">{symbol || "USDT"}</p>
                    </CsSuffix>
                </CsInput>
            </CsInputField>
        </CsInputSwapComponent>

    )
}