import { ChainId, Native } from '@pancakeswap/sdk'
import { bscTokens, onusMainnetTokens, polygonTokens, USDT } from '@pancakeswap/tokens'
import { TokenSupportConfig, configToken } from './type'

let logged = false

export const tokenSupport = async (chainId: number) => {
    try {
        return (await import(`/${chainId}.ts`)).default.filter((f: TokenSupportConfig) => f.toChainId !== null) as TokenSupportConfig[]
    } catch (error) {
        if (!logged) {
            console.error('Cannot get token config', error, chainId)
            logged = true
        }
        return []
    }
}

export const listTokens:configToken[] = [
    {
        name: "USDT",
        symbol: "USDT",
        fromChains: [
            {
                chainId: ChainId.POLYGON,
                token: polygonTokens.usdt,
                toChain: [ChainId.ONUS]
            },
            {
                chainId: ChainId.ONUS,
                token: onusMainnetTokens.usdt,
                toChain: [ChainId.POLYGON]
            },
            {
                chainId: ChainId.ETHEREUM,
                token: USDT[ChainId.ETHEREUM],
                toChain: [ChainId.ONUS]
            },
            {
                chainId: ChainId.BSC,
                token: bscTokens.usdt,
                toChain: [ChainId.ONUS]
            },
        ],
        toChains: [
            {
                chainId: ChainId.ONUS,
                token: onusMainnetTokens?.usdt,
                fromChain: [ChainId.POLYGON, ChainId.ETHEREUM, ChainId.BSC]
            },
            {
                chainId: ChainId.POLYGON,
                token: polygonTokens?.usdt,
                fromChain: [ChainId.ONUS, ChainId.POLYGON]
            },
            {
                chainId: ChainId.ETHEREUM,
                token: USDT[ChainId.ETHEREUM],
                fromChain: [ChainId.ONUS]
            },
            {
                chainId: ChainId.BSC,
                token: bscTokens.usdt,
                fromChain: [ChainId.ONUS]
            },
        ],
    },
    // {
    //     name: "ONUS",
    //     symbol: "ONUS",
    //     fromChains: [
    //         {
    //             chainId: ChainId.BSC,
    //             token: bscTokens.onus,
    //             toChain: [ChainId.ONUS]
    //         },
    //         {
    //             chainId: ChainId.ONUS,
    //             token: Native.onChain(ChainId.ONUS),
    //             toChain: [ChainId.BSC]
    //         },
    //     ],
    //     toChains: [
    //         {
    //             chainId: ChainId.ONUS,
    //             token: Native.onChain(ChainId.ONUS),
    //             fromChain: [ChainId.BSC]
    //         },
    //         {
    //             chainId: ChainId.BSC,
    //             token: bscTokens.onus,
    //             fromChain: [ChainId.ONUS]
    //         },
    //     ],
    // },
    {
        name: "VNDC",
        symbol: "VNDC",
        fromChains: [
            {
                chainId: ChainId.BSC,
                token: bscTokens.vndc2,
                toChain: [ChainId.ONUS]
            },
            {
                chainId: ChainId.ONUS,
                token: onusMainnetTokens?.vndc2,
                toChain: [ChainId.BSC, ChainId.POLYGON]
            },
            {
                chainId: ChainId.POLYGON,
                token: polygonTokens.vndc2,
                toChain: [ChainId.ONUS]
            },
        ],
        toChains: [
            {
                chainId: ChainId.ONUS,
                token: onusMainnetTokens?.vndc2,
                fromChain: [ChainId.BSC, ChainId.POLYGON]
            },
            {
                chainId: ChainId.BSC,
                token: bscTokens.vndc2,
                fromChain: [ChainId.ONUS]
            },
            {
                chainId: ChainId.POLYGON,
                token: polygonTokens.vndc2,
                fromChain: [ChainId.ONUS]
            },
        ],
    }
]