import { useTranslation } from '@pancakeswap/localization';

interface Props {
    feePercent: string
    addressToSymbol: string,
    feeMin: any
}
export const Note: React.FC<Props> = ({
    feePercent, addressToSymbol, feeMin
}) => {
    const { t } = useTranslation()
    return (
        <div className="flex flex-row lg:mt-[60px] mt-[40px] md:gap-[18px] gap-[0px] bg-[#FFFCF8] mb-0 md:mb-[41px] mb-0 md:p-[20px] p-0">
            <div className="py-[6px] pe-[8px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.4" d="M12.0001 7.89001L10.9301 9.75001C10.6901 10.16 10.8901 10.5 11.3601 10.5H12.6301C13.1101 10.5 13.3001 10.84 13.0601 11.25L12.0001 13.11" stroke="#E8A23A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.29986 18.04V16.88C5.99986 15.49 4.10986 12.78 4.10986 9.89999C4.10986 4.94999 8.65986 1.06999 13.7999 2.18999C16.0599 2.68999 18.0399 4.18999 19.0699 6.25999C21.1599 10.46 18.9599 14.92 15.7299 16.87V18.03C15.7299 18.32 15.8399 18.99 14.7699 18.99H9.25986C8.15986 19 8.29986 18.57 8.29986 18.04Z" stroke="#E8A23A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M8.5 22C10.79 21.35 13.21 21.35 15.5 22" stroke="#E8A23A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <div className="basis-11/12 p-[10px] md:p-0 flex justify-center flex-col py-[6px]">
                <h3 className="text-[#23262F] lg:text-[24px] text-[18px] font-bold mb-1">{t("Note")}</h3>
                <p className="text-[#777E91] lg:text-sm font-medium text-[12px]">{t("Crosschain Fee is (%percent% or %feeMin% %symbol% )", { percent: feePercent !== undefined ? `${feePercent}%` : "0%", feeMin: feeMin !== undefined ? `${feeMin}` : "0", symbol: addressToSymbol })}</p>
                <p className="text-[#777E91] lg:text-sm font-medium text-[12px]">{t("Estimated Time of Crosschain Arrival is 3s-1min")}</p>
                <p className="text-[#777E91] lg:text-sm font-medium text-[12px]">{t("Crosschain amount larger than 1,000,000 %symbol% could take up to 24 hours", { symbol: addressToSymbol })}</p>
            </div>
        </div>
    )
};