import { useTranslation } from "@pancakeswap/localization";
import { Text } from "@pancakeswap/uikit";
import React, { useMemo } from "react";
import { CsInput, CsInputField, CsInputSwapComponent, CsNumericFormat, CsSuffix } from "../styles";

const imgUSDT = "/images/coins/usdt.png";

interface Props {
    childrenPercent?: React.ReactNode
    balanceForFrom: string | number,
    fillAmount: string
    onChangeAmount?: (newValue: string) => void,
    symbol: string,
    isMinAmount: boolean,
    isMaxAmount: boolean,
    minAmount: number,
    maxAmount: number,
    isNotBalanceToTransfer
}
export const InputLeft: React.FC<Props> = ({
    balanceForFrom,
    fillAmount,
    onChangeAmount,
    symbol,
    isMinAmount,
    isMaxAmount,
    minAmount,
    maxAmount,
    isNotBalanceToTransfer,
    childrenPercent
}) => {
    const { t } = useTranslation()
    const renderImg = useMemo(() => {
        return `/images/coins/${symbol?.toLowerCase()}.png`
    }, [symbol])
    return (
        <CsInputSwapComponent>
            <CsInputField>
                <div className="flex justify-between">
                    <p className="text-[#131316] font-semibold text-[14px]">{t("Amount")}</p>
                    <p className="text-[#8F8F9B] font-semibold text-[14px]">{t("Balance")}: {balanceForFrom}</p>
                </div>
                <CsInput warming={isMinAmount || isMaxAmount || isNotBalanceToTransfer}>
                    <CsNumericFormat
                        value={fillAmount}
                        decimalScale={6}
                        thousandSeparator
                        placeholder={fillAmount || "0.0"}
                        // eslint-disable-next-line no-return-assign, no-param-reassign
                        onFocus={(e) => e.target.placeholder = ""}
                        onValueChange={(values) => {
                            const { value } = values;
                            if (Number(value) >= 0) {
                                onChangeAmount(value)
                            }
                        }}
                        maxLength={35}
                        allowNegative={false}
                    />
                    <CsSuffix>
                        <img className="sm:w-[32px] sm:h-[32px] w-6 h-6" src={symbol ? renderImg : imgUSDT} alt="" />
                        <p className="sm:text-sm text-xs">{symbol || "USDT"}</p>
                    </CsSuffix>
                </CsInput>
            </CsInputField>
            {(isMinAmount || isMaxAmount) &&
                <Text mt="14px" fontSize="14px" fontWeight={600} color="warning43">
                    {isMinAmount &&
                        t("The crosschain amount must be greater than %min% %token%", { min: Number(minAmount)?.toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 2 }), token: symbol })
                    }
                    {isMaxAmount &&
                        t("The crosschain amount must be less than %max% %token%", { max: Number(maxAmount)?.toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 2 }), token: symbol })
                    }
                </Text>
            }
            {isNotBalanceToTransfer &&
                <Text mt="14px" fontSize="14px" fontWeight={600} color="warning43">
                    {/* {t("Insufficient %token% to transfer", { token: symbol })} */}
                    {t("Your assets are not enough")}
                </Text>
            }
            {childrenPercent}
        </CsInputSwapComponent>
    )
}