import { MaxUint256 } from "@ethersproject/constants";
import * as React from 'react';
import { useBalance } from 'wagmi';

import { useTranslation } from '@pancakeswap/localization';
import { Currency } from '@pancakeswap/sdk';
import { useToast } from '@pancakeswap/uikit';
import BigNumber from 'bignumber.js';
import { ToastDescriptionWithTx } from 'components/Toast';
import contracts from "config/constants/contracts";
import { storeCrossChains, useAppDispatch, useAppSelector } from 'state';
import { getDataCrossChain } from 'state/cross-chains/actions';
import { HandleDataType, fetchDataCrossChain, fetchDataToken } from 'state/cross-chains/fetchData';
import { getAddress } from 'utils/addressHelpers';
import useActiveWeb3React from './useActiveWeb3React';
import { useCallWithMarketGasPrice } from './useCallWithMarketGasPrice';
import { useCsERC20, useSwap } from './useContract';

export const useCrossChains = (fillAmount: string, toChain: number, fromToken: any, toToken: Currency) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { toastSuccess, toastError } = useToast();
    const { chainId, account } = useActiveWeb3React();
    const nativeBalanceFrom = useBalance({ address: account, chainId: fromToken?.chainId });
    const nativeBalanceTo = useBalance({ address: account, chainId: toToken?.chainId });

    const balanceOfFrom = new BigNumber(nativeBalanceFrom?.data?.value?.toString()).dividedBy(1E18).toNumber();
    const balanceOfTo = new BigNumber(nativeBalanceTo?.data?.value?.toString()).dividedBy(1E18).toNumber();

    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();
    const resultStoreCrossChains = useAppSelector(storeCrossChains);

    const ContractBrideAddress = getAddress(contracts.bridge, chainId)

    const [loadingTx, setLoadingTx] = React.useState<boolean>(false);
    const [dataFromToken, setDataFromToken] = React.useState<HandleDataType>()
    const [dataToToken, setDataToToken] = React.useState<HandleDataType>()

    const addressApprove = useCsERC20(fromToken?.address);
    const swapContract = useSwap(ContractBrideAddress);
    const handleDataToken = React.useCallback(async () => {
        const resultFromToken = await fetchDataToken(account, fromToken, balanceOfFrom, ContractBrideAddress);
        const resultToToken = await fetchDataToken(account, toToken, balanceOfTo, ContractBrideAddress);
        setDataFromToken(resultFromToken)
        setDataToToken(resultToToken)
    }, [ContractBrideAddress, account, balanceOfFrom, balanceOfTo, fromToken, toToken])

    const handleApproveToken = React.useCallback(async () => {
        setLoadingTx(!false);
        try {
            const tx = await callWithMarketGasPrice(addressApprove, "approve", [ContractBrideAddress, MaxUint256]);
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(t("Contract enabel"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
            } else {
                toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
            }
        } catch (error) {
            console.log(error);
            toastError(t('Error'), t('Please try again. Transaction confirmation failed'))
        } finally {
            await handleDataToken();
            setLoadingTx(false);

        }
    }, [ContractBrideAddress, addressApprove, callWithMarketGasPrice, handleDataToken, t, toastError, toastSuccess]);

    const handleSwap = React.useCallback(async () => {
        const conVerSwapAmount = new BigNumber(fillAmount).multipliedBy(new BigNumber(10).pow(fromToken?.decimals)).toString();
        setLoadingTx(!false);
        try {
            let tx;
            if (fromToken?.isNative) {
                tx = await callWithMarketGasPrice(swapContract, "depositNative", [toChain], { value: conVerSwapAmount });
            } else {
                tx = await callWithMarketGasPrice(swapContract, "deposit", [fromToken?.address, conVerSwapAmount, toChain]);
            }
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(t("Swap successful"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
            } else {
                toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
            }

        } catch (error) {
            console.log(error);
            toastError(t('Error'), t('Please try again. Transaction confirmation failed'))

        } finally {
            await handleDataToken();
            setLoadingTx(false);
        }
    }, [callWithMarketGasPrice, fillAmount, fromToken?.address, fromToken?.decimals, fromToken?.isNative, handleDataToken, swapContract, t, toChain, toastError, toastSuccess]);


    React.useEffect(() => {
        (async () => {
            const crossChain = await fetchDataCrossChain();
            dispatch(getDataCrossChain({ data: crossChain }))
            await handleDataToken();
        })();
    }, [chainId, dispatch, handleDataToken, toChain]);

    React.useEffect(() => {
        const timer = setInterval(() => {
            (async () => {
                const crossChain = await fetchDataCrossChain();
                dispatch(getDataCrossChain({ data: crossChain }));
            })();
        }, 60000);
        return () => clearTimeout(timer);
    }, [dispatch, handleDataToken]);

    return {
        dataFromToken,
        dataToToken,
        handleApproveToken,
        handleSwap,
        resultStoreCrossChains,
        account,
        chainId,
        loadingTx,
    }
};