
import { Dropdown } from 'antd';
import clsx from 'clsx';

import { ChevronDownIcon } from '@pancakeswap/uikit';
import { configToken } from 'config/tokenSupport/type';
import { CsContainerText, CsSelectField } from "../styles";

interface SelectAssetFieldProps {
    label: string;
    options?: configToken[]
    noBorder?: boolean;
    lineBottom?: boolean;
    name: string,
    onChangeToken: (item: configToken) => void
    selectedToken?: string
    isDisabled: boolean
}


export const SelectAssetField = ({
    label,
    options = [],
    noBorder,
    lineBottom,
    onChangeToken,
    selectedToken,
    isDisabled = false
}: SelectAssetFieldProps) => {
    const handleChange = (value) => {
        onChangeToken(value);
    };


    if (options.length === 0) return null;

    const items = options?.map((item, index) => ({
        key: index,
        label: (
            <div
                className="flex gap-1 items-center gap-6 md:py-2 py-0"
                onClick={() => handleChange(item)}
                aria-hidden="true"
            >
                <img src={`/images/coins/${item.symbol.toLowerCase()}.png?version=4.0`} alt={item.name} className="sm:w-[32px] sm:h-[32px] w-6 h-6" />
                <span className={clsx("font-semibold sm:text-sm text-xs truncate", selectedToken?.toLowerCase() !== item?.name?.toLowerCase() ? "text-[#131316]" : "text-[#9C9CAB]")}>{item.name}</span>
            </div>
        )
    }));
    return (
        <CsSelectField
            noBorder={noBorder}
            lineBottom={lineBottom}
        >
            <Dropdown
                trigger={["click"]}
                menu={{
                    items,
                }}
                className="w-full"
                overlayStyle={{
                    borderRadius: "8px",
                    border: "1px solid #E3E3E8",
                }}
                disabled={isDisabled}
            >
                <div className="flex">
                    <CsContainerText>
                        <h4>{label}</h4>
                    </CsContainerText>
                    <div className="flex w-full items-center cursor-pointer">
                        <div className="flex gap-[7px] w-full items-center">
                            <img src={`/images/coins/${selectedToken?.toLowerCase()}.png?version=4.0`} alt={selectedToken} className="sm:w-[32px] sm:h-[32px] w-6 h-6" />
                            <span className="text-[#131316] sm:text-sm text-xs truncate font-bold">{selectedToken?.toUpperCase()}</span>
                        </div>
                        <ChevronDownIcon className="sm:mr-6 mr-0" />
                    </div>
                </div>
            </Dropdown>
        </CsSelectField>
    )
};

