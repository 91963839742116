import axios from 'axios'


const history = async (walletAddress:string) => {
	return (await axios.get(`/api/transaction/crosschain_history?&walletAddress=${walletAddress}`)).data
}
const summary = async () => {
	return (await axios.get(`/api/transaction/crosschain_summary?`)).data
}
const crossChain = async () => {
	return (await axios.get(`https://dev-api.goldbridge.io/api/transaction/data_for_crosschain?`)).data
}
export const listApi = {
	history,
	summary,
	crossChain
}
