import { ChevronDownIcon } from '@pancakeswap/uikit';
import { Dropdown } from 'antd';
import clsx from 'clsx';

import { Currency, Token } from '@pancakeswap/sdk';
import { CHAINS } from 'utils/wagmi';
import { CsContainerText, CsSelectField } from "../styles";

interface SelectFromFieldProps {
    label: string;
    options: {
        chainId: number,
        token: Currency | Token
    }[];
    noBorder?: boolean;
    lineBottom?: boolean;
    onChange: (value: number) => void;
    name: string;
    className?: string
    chainId?: number;
    selectFromChain: number
}

export const SelectFromField = ({
    label,
    options = [],
    noBorder,
    lineBottom,
    onChange,
    name,
    chainId,
    selectFromChain
}: SelectFromFieldProps) => {

    const handleChange = (id) => {
        onChange(id)
    };

    const renderChainName = (newChainId: number) => {
        if (chainId) {
            const findData = CHAINS.find((ch) => ch?.id === newChainId)
            return findData?.name
        }
        return null
    }
    const items = options.map((item, index) => ({
        key: index,
        label: (
            <div
                className="flex gap-1 items-center gap-6 md:py-2 py-0"
                onClick={() => handleChange(item.chainId)}
                aria-hidden="true"
            >
                <img src={`/images/chains/${item?.chainId?.toString()}.png?version=4.0`} alt={item?.chainId?.toString()} className="sm:w-[32px] sm:h-[32px] w-6 h-6" />
                <span className={clsx("font-bold sm:text-sm text-xs truncate", chainId !== item.chainId ? "text-[#131316]" : "text-[#9C9CAB]")}>
                    {renderChainName(item.chainId)}
                </span>
            </div>
        )
    }));
    const findData = CHAINS.find((ch) => ch?.id === selectFromChain)

    return (
        <CsSelectField
            noBorder={noBorder}
            lineBottom={lineBottom}
        >
            <Dropdown
                trigger={["click"]}
                menu={{
                    items,
                }}
                className="w-full"
                overlayStyle={{
                    borderRadius: "8px",
                    border: "1px solid #E3E3E8",
                }}
            >
                <div className="flex">
                    <CsContainerText>
                        <h4>{label}</h4>
                    </CsContainerText>
                    {
                        findData &&
                        <div className="flex w-full items-center cursor-pointer">
                            <div className="flex gap-[7px] w-full items-center">
                                <img src={`/images/chains/${selectFromChain.toString()}.png?version=5.0`} alt={selectFromChain?.toString()} className="sm:w-[32px] sm:h-[32px] w-6 h-6" />
                                <span className="text-[#131316] sm:text-sm text-xs truncate font-normal">{renderChainName(selectFromChain)}</span>
                            </div>
                            <ChevronDownIcon className="sm:mr-6 mr-0" />
                        </div>
                    }
                </div>
            </Dropdown>
        </CsSelectField>
    )
};
