import BigNumber from 'bignumber.js';
import { PERCENT_PRICE } from 'config';
import { PercentPriceType } from './type';

interface PercentPriceProps {
    balanceFrom: BigNumber;
    setFillAmount: (amount: string) => void;
}

export const PercentPrice = ({ setFillAmount, balanceFrom }: PercentPriceProps) => {
    return (
        <div className="flex gap-[10px] lg:mt-4 mt-[10px]">
            {
                PERCENT_PRICE.map((item: PercentPriceType) => (
                    <p
                        aria-hidden="true"
                        onClick={() => {
                            if (balanceFrom) {
                                setFillAmount(new BigNumber(item.value).multipliedBy(balanceFrom).decimalPlaces(4, 1).toString())
                            }
                        }}
                        key={item.value}
                        className="bg-[#e8a23a1a] lg:px-4 px-3 lx:py-1 py-[6px] rounded-[30px] text-[#E8A23A] lg:text-sm text-xs cursor-pointer hover:bg-[#E8A23A] hover:text-white font-bold transition duration-150 ease-in-out"
                    >
                        {item.title}
                    </p>
                ))
            }
        </div>
    )
};
