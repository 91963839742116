import { useTranslation } from "@pancakeswap/localization";
import { ChainId } from "@pancakeswap/sdk"

interface NoteSupportType {
    toChain: number;
    findTokenTo: any,
    bonus: any,
    amountMinBonus: any
}

export const NoteSupport = ({ toChain, findTokenTo, bonus, amountMinBonus }: NoteSupportType) => {
    const { t } = useTranslation();
    return (
        <div>
            {
                (toChain === ChainId.ONUS && Boolean(amountMinBonus)) &&
                <p className="text-[13px] text-[#131316] tracking-wide font-bold mt-[14px] pl-[18px] lg:ps-[78px] lg:ms-[50%]">
                    {t("Crosschain amount larger than %amount% %symbol% will support %support% ONUS", { amount: amountMinBonus, symbol: findTokenTo?.symbol , support: bonus || "0.01" })}
                </p>
            }
        </div>
    )
}
