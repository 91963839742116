import * as React from 'react';

import { useTranslation } from '@pancakeswap/localization';
import { CsBalanceForm } from '../styles';

interface Props {
    title: string,
    value: number,
}

export const BalanceForm = ({ title, value }): React.ReactElement<Props> => {
    const { t } = useTranslation();
    return (
        <CsBalanceForm>
            <h3>{t(title)}</h3>
            <p>{t("Balance")}: {value}</p>
        </CsBalanceForm>
    )
}
