import { Button, Text } from "@pancakeswap/uikit";
import NumberFormat from "react-number-format";
import styled from "styled-components";

export const CsContainer = styled.div`
    display: flex;
    background: #ffff;
    flex-direction: column;
    width: 100%;
    height:auto;
    @media screen and (max-width:1000px){
        background: linear-gradient(360deg, #FFF 0%, #FDF9ED 66.56%, #FFF 100%);
    }
`

export const CsContainerText = styled.div`
    background: #ffff;
    max-width: 60px;
    width: 100%;
    color: #000;
    border-right: 1px solid #E3E3E8;
    margin-top: 8px;
    margin-bottom:8px;
    margin-right: 24px;
    margin-left: 24px;
    @media screen and (max-width:1000px) {
        display: none;
    }
`

export const CsCarousel = styled.div`
    max-width: 1120px;
    width: 100%;
    margin: 40px auto 0 auto;
    @media screen and (max-width: 1000px) {
        margin: 20px auto 0 auto;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: radial-gradient(103.03% 103.03% at 0% 0%, #717171 0%, #242424 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        height: 195px;
        @media screen and (max-width:1000px) {
            border-radius: 0px;
            height: 250px;
        }
    }
    .swiper-pagination-bullet-active,.swiper-pagination-bullet{
        background: #ffff;
        width: 24px;
        height: 2px;
        border-radius: 1px;
        @media screen and (max-width:1000px) {
            width: 7px;
        }
    }
    .swiper-pagination{
        @media screen and (max-width:1000px) {
            display: flex;
            padding-left: 36px;
        }
    }
`
export const CsItemCarousel = styled.div`

    background: radial-gradient(143.58% 143.58% at -0.00% -0.00%, #717171 0%, #242424 100%);
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-radius: 24px;
    @media screen and (max-width:1000px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        display: grid;
        border-radius: 0;
        height: 100%;
    }
`
export const CsRightItemCarousel = styled.div`
    img.mobile{
        display: none;
        @media screen and (max-width:1000px) {
            display: block;
            width: 320px;
        }
    }
    img.desktop{
        display: block;
        @media screen and (max-width:1000px) {
            display: none;
        }
    }
    @media screen and (max-width:1000px) {
        align-self: flex-end;
        justify-self: flex-end;
    }
`
export const CsLeftItemCarousel = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-left: 19px;
    align-items: start;
    p{
        color: #ffff;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        @media screen and (max-width:1000px) {
            font-size: 8px;
        }
    }
    h3{
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        @media screen and (max-width:1000px) {
            font-size: 20px;
        }
    }
    span{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
        text-align: left;
        @media screen and (max-width:1000px) {
            padding: 14px 0;
            font-size: 10px;
        }
    }
    a{
        padding: 10px 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #11142D;
        height: 44px;
        background: #FFFFFF;
        border-radius: 12px;
        margin-top: 3px;
        @media screen and (max-width:1000px) {
            border-radius: 25px;
            font-size: 10px;
        }
    }
`
// CsFormSwap
export const CsFormSwap = styled.div`
    max-width: 1000px;
    margin: 48px auto;
    width: 100%;
    @media screen and (max-width:1000px) {
        padding:0 20px;
        margin: 26px auto;
    }
`
export const CsItemField = styled.div`
    border: 1px solid #E3E3E8;
    border-radius: 6px;
    width: 100%;
    @media screen and (max-width:1000px) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 15px;
        border: none;
    }
`

export const CsSelectField = styled.div<{ noBorder?: boolean, lineBottom?: boolean }>`
    display: flex;
    border: ${({ noBorder }) => noBorder ? "none" : "1px solid #E3E3E8"};
    align-items: center;
    border-radius: ${({ lineBottom }) => lineBottom ? 0 : "6px"};
    margin: 0;
    line-height: 40px;
    border-bottom: ${({ lineBottom }) => lineBottom ? "1px solid #E3E3E8" : "none"};
    height: 56px;
    width: 100%;
    @media screen and (max-width:1000px) {
        padding: 10px 12px;
        border: 1px solid #E3E3E8;
        border-radius: 6px;
        }
        .after-padding::after{
            margin-left: 47px !important;
        }
    h4{
        color: #8F8F9B;
        font-weight: 500;
        font-size: 14px;
        /* max-width: 120px;
        width: 100%; */


        @media screen and (max-width:1000px) {
            display: none;
        }
    }
    @media screen and (max-width: 600px){
        height: 40px;
        padding: 4px;
    }
`

export const CsWrapSelect = styled.div`
    display: flex;
    gap: 50px;
    background: transparent;
    @media screen and (max-width:1000px) {
        display: grid;
        gap: 20px;
    }
    @media screen and (max-width:600px) {
        display: grid;
        gap: 10px;
    }
`
export const CsInputSwapComponent = styled.div`

`
export const CsInputSwapRight = styled.div`

`
export const CsWrapItemField = styled.div`
    width: 100%;

    @media screen and (max-width:1000px) {
        background: #FFFFFF;
        box-shadow: 0px 4px 32px 12px rgba(210, 210, 210, 0.1);
        border-radius: 8px;
        padding: 17px;
    }
    @media screen and (max-width:768px) {
        background: #FFFFFF;
        box-shadow: 0px 4px 32px 12px rgba(210, 210, 210, 0.1);
        border-radius: 8px;
        padding: 10px;
    }
    ${CsInputSwapComponent},${CsInputSwapRight}{
        display: none;
        @media screen and (max-width:1000px) {
            display: block;
        }
    }

`

export const CsBalanceForm = styled.div`
    display: none;
    h3{
        color: #131316;
        font-weight: 600;
        font-size: 14px;
    }
    p{
        color: #9C9CAB;
        font-weight: 600;
        font-size: 14px;
    }
    @media screen and (max-width:1000px) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    
`

export const CsItemSelect = styled.div`
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width:600px){
        gap: 4px;
    }
    img{
        width: 32px;
        height: 32px;
        @media screen and (max-width:600px) {
            width: 22px;
            height: 22px;
        }
        @media screen and (max-width:400px) {
            width: 18px;
            height: 18px;
        }
    }
    ${Text}{

        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #131316;

    }
    p{

        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        @media screen and (max-width:600px) {
            font-size:12px;

        }
        @media screen and (max-width:400px) {
            font-size:12px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 120px;
        }
    }
`

export const CsInputField = styled.div`
    p{
        @media screen and (max-width:1000px){
        display: none;
        }
    }
`
export const CsInput = styled.div<{ warming?: boolean }>`
    display: flex;
    border: 1px solid #E3E3E8;
    border: ${({ warming }) => warming ? "1px solid var(--colors-warning43)" : "1px solid #E3E3E8"};
    padding: 14px 18px 14px 0px;
    border-radius: 8px;
    margin-top: 14px;
    p{
        margin-bottom: 14px;
        color: #131316;
        font-size: 14px;
        font-weight: 600;
    }
    input{
        border: none;
        outline: none;
        padding-left: 12px;
        width: 100%;
        background: transparent;
        color: #131316;
        font-size: 14px;
        font-weight: 600;
        &:focus-visible{
            border: none;
        }
        &::placeholder{
            color: #131316;
            font-size: 14px;
            font-weight: 600;
        }
    }
    @media screen and (max-width: 600px)
    {
        padding: 8px 18px 8px 0px;
        height: 40px;
    }
`
export const CsSuffix = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    padding-right: 14px;
    p{
        margin-bottom: 0;
        @media screen  and (max-width:1000px){
            display: block;
        }
        @media screen  and (max-width:600px){
            font-weight:600
        }
    }
    img{
        width: 20px;
        height: 20px;
    }
`
// InputSwap

export const CsInputSwap = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 150px;
    margin-top: 15px;
    @media screen  and (max-width:1000px){
        display: none;
    }
`

export const CsArrowRight = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    p{
        color: #8F8F9B;
        font-weight: 600;
        font-size: 14px;
    }
    @media screen  and (max-width:1000px){
        transform: rotate(90deg);
        margin-bottom: 0;
        /* width: 20px;
        height: 20px; */
        margin: auto;
        p{
            display: none;
        }
    }
    @media screen and (max-width:600px) {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
`

export const CsButtonSwap = styled(Button)`
    min-width: 328px;
    height: 48px;
    background: #E8A23A;
    border-radius: 8px;
    @media screen and (max-width:640px) {
        min-width: 100%;
        height: 40px;
        font-size: 13px;
    }
`

export const CsWapButtonSwap = styled.div`
    display: flex;
    margin-top: 40px;
    justify-content: center;
    @media screen and (max-width:640px) {
        margin-top: 20px;
    }
`

export const CsNumericFormat = styled(NumberFormat)`
    background-color: transparent;
    outline: none;
    font-weight: 600;
    &:focus-visible {
        outline: none;
    }
    ::placeholder {
        color:${({ theme }) => theme.colors.text};
        opacity: 1;
    }
`

export const CsWrapButton = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 100%;
    display: flex;
    justify-content:center;
    cursor: pointer;
    background: white;
    box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
    &:active{
        transform: translateY(1px);
        @media screen  and (max-width:1000px){
            transform: translateX(1px);
        }
    }
`