import { Currency, SerializedToken } from '@pancakeswap/sdk';
import multicall from 'utils/multicall';
import { listApi } from 'config/api/listApi';
import BigNumber from 'bignumber.js';
import { ERC20_ABI } from 'config/abi/erc20';
import { MAX_UINT_256 } from 'config';
import { CheckListAllowanceType, ListAllowanceTokenType } from './type';

type HandleType = CheckListAllowanceType & {
    balanceOf?: number | string
}

export type HandleDataType = SerializedToken & Currency & {
    balanceOf: number,
    allowance: number,
    isAllowance: boolean,
}

export const fetchDataListAllowanceFromToken = async (payload: HandleType): Promise<ListAllowanceTokenType> => {
    try {
        let dataCurrency;
        let dataTemplate;
        const { tokenSupport, spender, account, chainId, balanceOf: balanceOfIsNative } = payload;
        const listIsNative = [...tokenSupport].filter((item: Currency) => item.isNative);
        const listToken = [...tokenSupport].filter((item: Currency) => !item.isNative);
        if (listIsNative.length) {
            dataCurrency = listIsNative.map((item: Currency) => ({
                ...item,
                balanceOf: balanceOfIsNative,
                allowance: MAX_UINT_256,
                isAllowance: true,
            }));
        }

        if (listToken?.length) {

            const getAllowance = async () => {
                const calls = [...listToken]?.map((item: SerializedToken) => ({
                    address: item.address,
                    name: "allowance",
                    params: [account, spender]
                }));
                const allowance = await multicall(ERC20_ABI, calls, chainId);
                return allowance;
            };

            const getBalanceOf = async () => {
                const calls = [...listToken]?.map((item: SerializedToken) => ({
                    address: item.address,
                    name: "balanceOf",
                    params: [account]
                }));
                const balanceOf = await multicall(ERC20_ABI, calls, chainId);
                return balanceOf;
            };

            const [resultAllowance, resultBalanceOf] = await Promise.all(
                [
                    getAllowance(),
                    getBalanceOf(),
                ]
            );

            dataTemplate = listToken?.map((item, index) => {
                const allowance = new BigNumber(resultAllowance[index].toString()).dividedBy(new BigNumber(10).pow(listToken[index].decimals)).toNumber();
                const balanceOf = new BigNumber(resultBalanceOf[index].toString()).dividedBy(new BigNumber(10).pow(listToken[index].decimals)).toNumber();
                const isAllowance = allowance !== 0;

                return {
                    allowance,
                    isAllowance,
                    balanceOf,
                    ...item,
                }
            });
        }
        return {
            listAllowanceToken: dataCurrency.concat(dataTemplate)
        };
    } catch (error) {
        console.log(error);
        return {
            listAllowanceToken: []
        };
    }
};

export const fetchDataListAllowanceToToken = async (payload: HandleType): Promise<ListAllowanceTokenType> => {
    try {
        let dataTemplate;
        const { tokenSupport, account, chainId, balanceOf: balanceOfIsNative } = payload;
        const listIsNative = [...tokenSupport].filter((item: Currency) => item.isNative);
        if (listIsNative.length) {
            dataTemplate = listIsNative.map((item: Currency) => ({
                ...item,
                balanceOf: balanceOfIsNative,
            }));
        } else {
            const listTokenSupport = [...tokenSupport].filter((item: Currency) => !item.isNative);
            const calls = [...listTokenSupport].map((item: SerializedToken) => ({
                address: item.address,
                name: "balanceOf",
                params: [account]
            }));

            const resultBalanceOf = await multicall(ERC20_ABI, calls, chainId);
            dataTemplate = [...tokenSupport].map((item, index) => {
                const balanceOf = new BigNumber(resultBalanceOf[index].toString()).dividedBy(new BigNumber(10).pow(tokenSupport[index].decimals)).toNumber();
                return {
                    balanceOf,
                    ...item
                }
            });
        }
        return {
            listAllowanceToken: dataTemplate
        };
    } catch (error) {
        console.log(error)
        return {
            listAllowanceToken: []
        }
    }
}


export const fetchDataToken = async (account: string, fromToken: any, balanceOfFrom: number, spender: string): Promise<HandleDataType> => {
    try {
        let dataTemplate;
        if (fromToken.isNative) {
            dataTemplate = {
                ...fromToken,
                balanceOf: balanceOfFrom,
                allowance: MAX_UINT_256,
                isAllowance: true,
            }
        } else {
            const getAllowance = async () => {
                const allowance = await multicall(ERC20_ABI, [{ address: fromToken?.address, name: "allowance", params: [account, spender] }], fromToken?.chainId);
                return allowance;
            };
            const getBalanceOf = async () => {
                const balanceOf = await multicall(ERC20_ABI, [{ address: fromToken?.address, name: "balanceOf", params: [account] }], fromToken?.chainId);
                return balanceOf;
            };
            const [resultAllowance, resultBalanceOf] = await Promise.all(
                [
                    getAllowance(),
                    getBalanceOf(),
                ]
            );
            const allowance = new BigNumber(resultAllowance[0].toString()).dividedBy(new BigNumber(10).pow(fromToken?.decimals)).toNumber();
            const balanceOf = new BigNumber(resultBalanceOf[0].toString()).dividedBy(new BigNumber(10).pow(fromToken?.decimals)).toNumber();
            const isAllowance = allowance !== 0;
            dataTemplate = {
                ...fromToken,
                balanceOf,
                allowance,
                isAllowance,
            }
        }
        return dataTemplate
    } catch (error) {
        return {} as HandleDataType;
    }
};


export const fetchDataCrossChain = async () => {

    try {
        const res = await listApi.crossChain()
        return {
            dataCrossChain: res?.data
        }

    } catch (error) {
        console.log(error)
        return {
            listHistory: null
        }
    }
}